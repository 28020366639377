<template>
  <div>
    <CButton
        v-if="btnRemarkDepartmentTender()"
        color="danger"
        v-bind="$attrs"
        class="sideBar_btn"
        @click="openModalRemarkDepartmentTender(true)"
    >
      Зауваження
    </CButton>
    <CModal
        color="danger"
        title="Зауваження по тендеру"
        :show.sync="modalRemarkDepartmentTender"
        size="lg"
        addContentClasses="srl"
    >
      <template #body-wrapper>
        <CCardHeader class="">
          <h4>Вкажіть свої зауваження чи пропозицію:</h4>
          <my-alert message="message"/>
        </CCardHeader>
        <CCardBody class="p-1">
          <CTextarea
              placeholder="Напишіть свої зауваження"
              cols="15"
              rows="3"
              class="m-2"
              v-model="comment"
          ></CTextarea>
        </CCardBody>
      </template>
      <template #footer>
        <CButton
            color="secondary"
            @click="openModalRemarkDepartmentTender()"
        >
          Скасувати
        </CButton>
        <CButton
            color="danger"
            @click="whatIsRemark()"
        >
          Відправити
        </CButton>
      </template>
    </CModal>
  </div>
</template>
<script>
import {can} from "@/rules/permissions";
import axios from "axios";
import {confirmTender} from "@/api/confirmTender";
import MyAlert from "~/Components/Alert/MyAlert.vue";

export default {
  components: {MyAlert},
  props: {
    order: {
      type: Object,
      required: true,
      default: undefined
    }
  },
  data() {
    return {
      modalRemarkDepartmentTender: false,
      comment: '',
      message: null,
    }
  },
  methods: {
    confirmTender,
    btnRemarkDepartmentTender() {
      if (can('procurement_department_remark_tender') && ['ConfirmManager'].includes(this.$props.order.status)) {
        return true
      }
      if (can('admin_remark_tender') && ['ConfirmManager', 'NetworkValidation', 'ManagerValidation'].includes(this.$props.order.status)) {
        return true
      }
      if (can('manager_remark_tender') && ['ManagerValidation', 'ConfirmManager'].includes(this.$props.order.status)) {
        return true
      }
      return false;
    },
    openModalRemarkDepartmentTender(val = false) {
      this.modalRemarkDepartmentTender = val;
      if (!val) this.comment = "";
    },
    whatIsRemark() {
      if ((can('procurement_department_remark_tender') || can('manager_remark_tender')) && ['ConfirmManager'].includes(this.$props.order.status)) {
        return this.sendComments();
      }
      if (can('admin_remark_tender') && ['ConfirmManager', 'NetworkValidation', 'ManagerValidation'].includes(this.$props.order.status)) {
        return this.confirmTenderF(false);
      }
      if (can('manager_remark_tender') && ['ManagerValidation'].includes(this.$props.order.status)) {
        return this.confirmTenderF(false);
      }
    },
    sendComments() {
      let self = this;
      axios.post(`/api/orders/${self.$route.params.id}/remark`, {comment: self.comment})
          .then(() => {

            this.setMessage('Зауваження надіслане!', 'success');
            // self.funcShowTender(); update
            setTimeout(() => {
              this.openModalRemarkDepartmentTender();
              this.$router.go();
            }, 3000)
          })
          .catch((err) => {
            this.setMessage(err.response.data, 'danger');
          });
    },
    confirmTenderF() {
      confirmTender(this.$props.order.id, 0, false, this.comment)
          .then(resp => {
            if (resp === 'done') {
              this.setMessage('Зауваження надіслане!', 'success');
              setTimeout(() => {
                this.openModalRemarkDepartmentTender();
                this.$router.go();
              }, 3000)
            }
          })
          .catch(err => {
            this.setMessage(err, 'danger');
          });
    },
    setMessage(message, type) {
      this.message = [{message: message, type: type}]
      setTimeout(() => {
        this.message = null;
      }, 3000)
    }
  }
}
</script>