<template>
  <CModal
      :color="type === 'continue'? 'info' : 'primary'"
      :title="type === 'continue' ? 'Продовження тендера' : 'Відкриття тендеру'"
      :show.sync="showModal"
      size="lg"
      addContentClasses="srl"
  >
    <template #body-wrapper>
      <my-alert :message="message"/>
      <CCardHeader class="mx-0 px-0 text-left">
        <h4>Виберіть дату {{ type === 'continue' ? 'продовження' : 'завершення' }} тендеру</h4>
        <p>Бажане підключення: {{ timeConvert(order.date_connection) }}</p>
      </CCardHeader>
      <vc-date-picker
          :attributes="$options.attributes"
          :placeholder="`На скільки часу ${type === 'continue' ?'продовжити':'відкривати'} тендер`"
          :popover="{placement: 'bottom', visibility: 'click'}"
          v-model='toDay'
          class="m-2"
          color="gray"
          :min-date="min_day"
      />
    </template>
    <template #footer>
      <CButton
          @click="continueTender()"
          color="info">
        Продовжити
      </CButton>
    </template>
  </CModal>
</template>
<script>
import timeConvertMixin from '@/mixins/timeConvertMixin';
import MyAlert from "~/Components/Alert/MyAlert.vue";

export default {
  components: {MyAlert},
  mixins: [timeConvertMixin],
  props: {
    showModal: {required: true, default: false, type: Boolean},
    order: {required: true, default: undefined, type: Object},
    message: {required: false, default: undefined, type: [Object, String, Array]},
    type: {
      type: String,
      required: true,
      default: '',
      validator: val => ['open', 'continue'].includes(val)
    }
  },
  data() {
    return {
      toDay: undefined,
      min_day: new Date()
    }
  },
  attributes: [
    {
      key: 'Виберіть дату тендера',
      highlight: true,
      dates: new Date()
    }
  ],
  watch: {
    showModal(val) {
      if (!val) return this.close();
    }
  },
  methods: {
    continueTender() {
      if (this.$props.type === 'continue') return this.$emit('continueTender', this.toDay);
      return this.$emit('openTender', this.toDay);
    },
    close() {
      this.$emit('close');
    }
  }
}
</script>