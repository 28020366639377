<template>
  <div class="sidebar-wrapper" :class="{ 'active': isOpen }">
    <button v-show="elementIntoSideBar" @click="toggleMenu()" class="btn toggle-button">
      <span>{{ btnTitle }}</span>
    </button>

    <div class="overlay" @click="toggleMenu" v-if="isOpen"></div>

    <div class="sidebar" ref="sidebar" v-show="isOpen">
      <slot name="content"/>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    btnTitle: {default: 'Відпрацювати', type: String, required: false},
  },
  data() {
    return {
      isOpen: false,
      elementIntoSideBar: 0
    }
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    onContentLoaded() {
      const sideBar = this.$refs.sidebar;
      if (sideBar) {
        const btn = document.querySelectorAll('.sideBar_btn');
        this.elementIntoSideBar = btn.length;
      }
    }
  },
  watch: {
    async '$slots.content'(){
      await this.$nextTick();
      this.onContentLoaded();
    }
  },

}
</script>

<style lang="scss" scoped>
.sidebar-wrapper {
  position: absolute;
  //min-width: 380px;
  //max-width: 380px;
  transition: width 0.3s;
  top: 0;
  right: 0;
  height: 100%;
  // margin-right: -380px;

  &.active {
    .sidebar {
      margin-right: 0;
    }

    .toggle-button {
      margin-right: 380px;
    }
  }
}

.toggle-button {
  display: inline-block;
  position: fixed;
  top: 50%;
  right: 0;
  margin-right: 0;
  background-color: rgba(139, 173, 205, 0.87);
  border: 1px solid rgb(139, 173, 205);
  border-radius: 10px 0 0 10px;
  padding: 50px 10px;
  min-height: 130px;
  z-index: 1001;
  transition: right 0.3s;
  transform: translate(0, -50%);

  span {
    color: #ffffff;
    font-size: 20px;
    writing-mode: vertical-rl;
    transform: rotate(-180deg);
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  background-color: rgba(65, 65, 65, 0.7);
  min-width: 380px;
  max-width: 380px;
  overflow-x: hidden;
  padding: 55px 10px;
  transition: width 0.3s, right 0.3s;
  z-index: 1002;
}
</style>