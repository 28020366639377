<template>
  <div>
    <CButton
        v-if="type === 'cancel' ? btnCancelTender(): btnCloseWithoutWinner()"
        color="danger"
        v-bind="$attrs"
        class="sideBar_btn"
        @click="showModal(true)"
    >
      <slot>{{ type === 'cancel' ? 'Відмінити Тендер' : 'Закрити без переможця' }}</slot>
    </CButton>
    <CModal
        color="danger"
        :title="type === 'cancel'? 'Відміна тендеру' : type === 'close'? 'Закритя тендеру' :''"
        :show.sync="closed_or_cancel_tender_modal"
        size="lg"
        addContentClasses="srl"
    >
      <template #body-wrapper>
        <CCardHeader>
          <h4 v-if="type === 'cancel'">Тендер буде відмінено, напишіть коментар</h4>
          <h4 v-if="type === 'close'">Тендер буде закрито, напишіть коментар</h4>
        </CCardHeader>
        <CTextarea
            placeholder="Напишіть коментар"
            cols="15"
            rows="3"
            class="m-2"
            v-model="comment"
        ></CTextarea>
      </template>
      <template #footer>
        <CButton
            color="secondary"
            @click="showModal()"
        >
          Скасувати
        </CButton>
        <CButton
            color="danger"
            @click="cancel_or_closed_tender"
        >
          {{ type === 'cancel' ? 'Відмінити Тендер' : 'Закрити тендер' }}
        </CButton>
      </template>
    </CModal>

  </div>
</template>
<script>
import {can} from "@/rules/permissions";
import axios from "axios";

export default {
  props: {
    type: {
      type: String,
      required: true,
      defaults: '',
      validator: val => ['cancel', 'close'].includes(val)
    },
    order: {
      required: true,
      default: undefined,
      type: Object
    }
  },
  data() {
    return {
      comment: '',
      closed_or_cancel_tender_modal: false,
    }
  },
  methods: {
    btnCancelTender() {
      if (can('cancel_tender') && !['ClosedWithoutWinner', 'Closed', 'CloseWithResult'].includes(this.$props.order.status)) {
        return true;
      }
      if (can('cancel_tender_remark') && ['ResolveComments'].includes(this.$props.order.status)) {
        return true;
      }
      return false;
    },
    btnCloseWithoutWinner() {
      if (can('admin_close_tender_without_winner') && ['SummarizeOrder', 'ConfirmMainOffice', 'OpenOrder', 'ConfirmManager', 'ConfirmITRP'].includes(this.$props.order.status)) {
        return true;
      }
      if (can('manager_close_tender_without_winner') && ['ConfirmManager'].includes(this.$props.order.status)) {
        return true;
      }

      return false;
    },
    showModal(val) {
      this.closed_or_cancel_tender_modal = val;
    },
    cancel_or_closed_tender() {
      let self = this;
      let {type, order} = {...this.$props};

      axios.post(`/api/orders/${order.id}/${type}`, {comment: self.comment}).then(function () {
        self.showModal(false);
        self.$router.push({path: '/tenders'});
      }).catch(() => {
      })
    },
  }
}
</script>