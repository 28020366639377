<template>
  <div>
    <CButton
        v-if="btnShowWithRules()"
        color="dark"
        v-bind="$attrs"
        class="sideBar_btn"
        @click="showModal(true);"
    >
      Делегувати
    </CButton>
    <modal-delegate-person :offer-i-d="$route.params.id" :show-modal-delegate-person="showModalDelegateTender"
                           @close="showModal"/>
  </div>
</template>
<script>
import {can} from "@/rules/permissions";
import {managerContinueTender} from "@/api/continueTender";
import ModalDelegatePerson from "~/Components/Modal/ModalDelegatePerson.vue";

export default {
  components: {
    ModalDelegatePerson
  },
  props: {
    order: {
      type: Object,
      default: undefined,
      required: true
    }
  },
  data() {
    return {
      showModalDelegateTender: false,
    }
  },
  methods: {
    managerContinueTender,
    showModal(show = false) {
      this.showModalDelegateTender = show;
    },
    btnShowWithRules() {
      return can('delegate_tender') && this.$props.order.status === 'ConfirmManager';
    },
  }
}
</script>