<template>
  <CModal
      color="dark"
      title="Делегувати повноваження"
      :show.sync="showModalDelegatePerson"
      size="lg"
      addContentClasses="srl"
  >
    <template #body-wrapper>
      <CCardHeader class="mx-0 px-2 border-bottom-0">
        <h4>Виберіть користувача для делегування своїх повноважень</h4>
        <CAlert v-if="myAlert.text.length" show :color="myAlert.color">{{ myAlert.text }}</CAlert>
      </CCardHeader>
      <CCardBody class="p-1">
        <div class="form-item" :class="{'errorInput': $v.zmSelected.$error}">
          <multiselect
              placeholder="Зробіть вибір"
              class="mb-2"
              :options="zmList"
              label="name"
              :show-labels="false"
              v-model.trim="zmSelected"
              @change="$v.zmSelected.$touch()"
              :class=" {
                      'error': $v.zmSelected.$error
                    }"
              required
          />
          <small v-if="!$v.zmSelected.required" class="form-text errorText w-100">Необхідно вибрати на кого
            делегувати повноваження.</small>
        </div>
      </CCardBody>

    </template>
    <template #footer>
      <CButton
          v-if="loading"
          color="dark"
          class="mb-1 px-5"
          disabled
      >
        <div class="align-items-center">
          <CSpinner color="Light" size="sm"/>
        </div>
      </CButton>
      <CButton
          v-else
          @click="setDelegatePerson()"
          color="dark">
        Делегувати
      </CButton>
    </template>
  </CModal>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import {getProcurementManagers, SetDelegatePerson} from "@/api/delegatePerson";
import Multiselect from 'vue-multiselect';

export default {
  props: {
    showModalDelegatePerson: {
      type: Boolean,
      default: false,
      required: true
    },
    offerID: {
      type: String,
      default: '',
      required: true
    },
  },
  components:{ Multiselect},
  data: () => {
    return {
      loading: false,
      zmList: [],
      zmSelected: null,
      myAlert: {
        text: '',
        color: ''
      }
    }
  },
  validations: {
    zmSelected: {
      required,
      login: {
        required
      }
    }
  },
  watch: {
    showModalDelegatePerson(val) {
      if (!val) return this.close();
      this.getDelegatePerson();
    }
  },
  methods: {
    async getDelegatePerson() {
      this.zmList = await getProcurementManagers();
    },
    async setDelegatePerson() {
      this.clear();
      this.$v.$touch();
      if (this.$v.$invalid) return;

      const resp = await SetDelegatePerson(this.$props.offerID, this.zmSelected.login);
      if (resp === 'done') {
        this.myAlert.color = 'success';
        this.myAlert.text = `Ви успішно делегували обов'язки!`;
      } else {
        this.myAlert.color = 'danger'
        this.myAlert.text = resp || 'Сталась помилка! Дія не виконалась!';
      }

      setTimeout(() => {
        this.close();
      }, 2000);
    },
    clear() {
      this.$v.$reset();
      this.myAlert = {
        color: 'success',
        text: ''
      };
    },
    close() {
      this.clear();
      this.$emit('close');
    }
  }
}

</script>