import axios from "axios";

const APIProcurementManagers = () => {
  try {
    return axios.get(`/api/staff/procurement/managers`).then(resp => resp.data);
  } catch (e) {
    return e.response.data;
  }
}

const APISetDelegatePerson = (offerId, zmSelected) => {
  try {
    return axios.post(`/api/orders/${offerId}/send/procurement/${zmSelected}`).then(() => 'done');
  } catch (e) {
    return e.response.data;
  }
}

export const getProcurementManagers = async () => {
  const response = await APIProcurementManagers();
  return response;
}

export const SetDelegatePerson = async (offerId, zmSelected) => {
  if (!offerId || !zmSelected) return 'Заповніть поля';

  const response = await APISetDelegatePerson(offerId, zmSelected);

  return response;
}