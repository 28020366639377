import axios from "axios";
import timeConvertMixin from '@/mixins/timeConvertMixin';
import moment from "moment/moment";

const ApiManagerContinueTender = (id, day) => {
    return new Promise((resolve, reject) => {
        axios.post(`/api/orders/${id}/continue`, {date: day})
            .then(resp => resolve(resp))
            .catch(err => reject(err.response.data));
    });
};

export const managerContinueTender = (id, day) => {
    return new Promise((resolve, reject) => {

        if (!moment.unix(day).isValid()) return reject('Дату введено не прравильно');
        if (!id) return reject('Ввідсутній ордер id');

        Promise.all([ApiManagerContinueTender(id, day)])
            .then(resp => resolve('done'))
            .catch(err => reject(err));
    });
};