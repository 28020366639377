import axios from "axios";
import moment from "moment/moment";

const ApiConfirmTender = (obj) => {
    let {id, day, comment, validate} = {...obj};

    return new Promise((resolve, reject) => {
        axios.post('/api/orders/verify', {id: id, comment: comment, success: validate, day_to_end: day})
            .then(() => resolve('done'))
            .catch(err => reject(err.response.data))
    });
};


export const confirmTender = (id, day, validate, comment = '') => {
    return new Promise((resolve, reject) => {
        if (!moment.unix(day).isValid()) return reject('Дату введено не прравильно');
        if (!id) return reject('Ввідсутній ордер id');

        Promise.all([ApiConfirmTender({day, id, validate, comment})])
            .then(() => resolve('done'))
            .catch(err => reject(err));
    })
}