<template>
  <div>
    <CButton
        v-if="type === 'continue'? rulesContinueTender() : rulesOpenTender()"
        :color="type === 'continue'? 'info' : 'success'"
        v-bind="$attrs"
        class="sideBar_btn"
        @click="showModal(true)"
    >
      {{type === 'continue'? 'Продовжити тендер' : 'Опублікувати тендер'}}
    </CButton>
    <modal-manager-continue-tender
        :show-modal="showModalContinueTender" :order="{id: order.id, date_connection: order.date_connection}"
        @close="showModal(false)" @continueTender="continueTender" @openTender="openTender"
        :message="message" :type="type"
    />

  </div>
</template>
<script>
import {can} from "@/rules/permissions";
import timeConvertMixin from "@/mixins/timeConvertMixin";
import ModalManagerContinueTender from "~/Components/Modal/ModalManagerContinueTender.vue";
import {managerContinueTender} from "@/api/continueTender";
import {confirmTender} from "@/api/confirmTender";

export default {
  components: {ModalManagerContinueTender},
  mixins: [timeConvertMixin],
  props: {
    order: {
      required: true,
      default: undefined,
      type: Object
    },
    type: {
      type: String,
      required: true,
      default: '',
      validator: val => ['open', 'continue'].includes(val)
    }
  },
  data() {
    return {
      showModalContinueTender: false,
      message: null,
    }
  },
  methods: {
    managerContinueTender,
    confirmTender,
    showModal(show = false) {
      this.showModalContinueTender = show;
    },
    rulesContinueTender() {
      if (can('admin_continue_tender') && ['ConfirmManager', 'SummarizeOrder', 'ConfirmITRP', 'ConfirmMainOffice'].includes(this.$props.order.status)) {
        return true;
      }
      if (can('manager_continue_tender') && ['ConfirmManager'].includes(this.$props.order.status)) {
        return true;
      }

      return false;
    },
    rulesOpenTender() {
      return can('manager_open_tender') && ['ManagerValidation'].includes(this.$props.order.status);
    },
    continueTender(day) {
      managerContinueTender(this.$props.order.id, +this.timeUnix(day))
          .then(resp => {
            if (resp === 'done') {
              this.setMessage('Тендер продовжено!', 'success');
              setTimeout(() => {
                this.showModal();
                this.$router.go();
              }, 3000)
            }
          })
          .catch(err => {
            this.setMessage(err, 'danger');
          });
    },
    openTender(day) {
      confirmTender(this.$props.order.id, +this.timeUnix(day), true)
          .then(resp => {
            if (resp === 'done') {
              this.setMessage('Тендер відкрито!', 'success');
              setTimeout(() => {
                this.showModal();
                this.$router.go();
              }, 3000)
            }
          })
          .catch(err => {
            this.setMessage(err, 'danger');
          });
    },
    setMessage(message, type) {
      this.message = [{message: message, type: type}]
      setTimeout(() => {
        this.message = null;
      }, 3000)
    }
  }
}
</script>