<template>
  <div>
    <ul v-if="Array.isArray(messageData)" style="list-style: none" class="p-0">
      <li v-for="mess of messageData" :key="messageData.indexOf(mess)">
        <CAlert
            style="font-size: 12px;"
            class="text-left"
            :show="mess.show"
            :color="mess.type"
        >{{ mess.message }}
        </CAlert>
      </li>
    </ul>
    <CAlert v-if="typeof messageData === 'string'"
            style="font-size: 12px;"
            class="text-left"
            :show="showAlert"
            :color="type">{{ messageData }}
    </CAlert>
  </div>
</template>
<script>

export default {
  props: {
    message: {
      required: true,
      default: null,
      validator: val => val === null || typeof val === 'undefined' || typeof val === 'string' || Array.isArray(val) || typeof val === 'object'
    },
    type: {
      required: false,
      default: "info",
      type: String
    }
  },
  data() {
    return {
      showAlert: false,
      messageData: undefined
    }
  },
  methods: {
    hideAlert(val) {
      this.showAlert = val;
    },
    updateMessage() {
      if (Array.isArray(this.messageData)) {
        this.messageData.forEach((mess) => {
          mess.show = true;
          setTimeout(() => {
            mess.show = false;
          }, 3000);
        });
      } else if (typeof this.messageData === 'string') {
        this.hideAlert(true);
        setTimeout(() => {
          this.hideAlert(false)
        }, 3000);
      }
    }

  },
  watch: {
    message: {
      handler(newVal) {
        this.messageData = newVal;
        this.updateMessage();
      },
      deep: true
    }
  }
}
</script>